import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Waaromitems from "./Waaromitems.json"
import Componentheader from "./componentHeader"
import { device } from "../../queries"
import { render } from "react-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSeedling, faIdCard, faHandshake, faRecycle } from "@fortawesome/free-solid-svg-icons"
import Laptopimage from "./laptopimage"
import DienstImages from "./dienstimages"



const Container = styled.div`
    
    background-color: var(--dark-background);
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: 0 20px 25px rgba(0,0,0,0.15);
`

const Innercontainer = styled.div`
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: space-between;
    
`
const Flexcontainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 40%;
    justify-content: space-between;
    align-items: center;
    box-sizing: content-box;
    @media ${device.laptop}{
        max-width: 100%;
        padding: 20px;
    }
`


const Itemcontainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 200;
    margin-bottom: 40px;

    
    @media ${device.laptopL}{
    }
    @media ${device.tablet}{
        flex-direction: column;
    }
    @media ${device.mobilel}{

    }    
`


const Item = styled.div`
    width: 47%;
    box-sizing: border-box;
    padding: 0;
    position: relative;
    border-bottom: 3px solid rgba(255,255,255,0.3);
    padding-bottom: 10px;
    h4{
        color: white;
        display: inline-block;
        font-weight: 900;

    }
    :nth-child(-n+2){
        margin-bottom: 40px;
    }
    p{
        color: rgba(255,255,255,0.9);
        font-size: 16px;
        font-weight: 300;

    }
    @media ${device.tablet}{
        width: 100%;
        margin-bottom: 40px;
        &:nth-child(4){
            margin-bottom: 0;
        }
    }

`

const Diensttext = styled.p`
    color: rgba(255,255,255,0.9);
    font-weight: 300;
    margin-bottom: 50px;



    
`

const Fotocontainer = styled.div`
    width: 50%;
    max-width: 700px;
    height: 100%;
    position: relative;
    perspective: 110vh;
    display: grid;
    display: none;
    @media ${device.laptop}{
        display:none;
    }
    
   
`
const Styledlaptop = styled(Laptopimage)`
    transform: scaleX(-1);
    position: absolute;
    grid-column: 1;
    grid-row: 1;
    
`

const DienstimageContainer = styled.div`
    transform: rotateY(-26deg) scale3d(0.6, 0.6, 1)translate3D(19%, -23%, 0) skew(-6.5deg, 7deg);
    transition: opacity 1s cubic-bezier(.44,0,.17,1);
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    grid-column: 1;
    grid-row: 1;
    box-shadow: 0 0 100px rgba(255,255,255,0.6);
    opacity: 1;
    
    
    
`
const SkewY = styled.div`

`
const Skew = styled.div`
`
const Position = styled.div`

`



class Waarom extends React.Component {

    constructor(props) {
        super(props);
        this.fotoRef = React.createRef();
        this.Dothis = this.Dothis.bind(this);

    }

    Dothis(id) {
        this.fotoRef.current.children[id].classList.add("visibility");
        this.fotoRef.current.classList.add("visibility");

    }

    Dothat(id) {
        this.fotoRef.current.children[id].classList.remove("visibility");
        this.fotoRef.current.classList.remove("visibility");


    }
    iconSwitch(icoon) {
        switch (parseInt(icoon, 16)) {
            case 0:
                return (<Icoon icon={faRecycle} />);

            case 1:
                return (<Icoon icon={faIdCard} />);
            case 2:
                return (<Icoon icon={faSeedling} />)
            case 3:
                return (<Icoon icon={faHandshake} />)
        }
    }

    render() {

        return (
            <>
                <Componentheader header="WAAROM GREENDIGITAL" />
                <Container>
                    <Flexcontainer>

                        <Diensttext>Green Digital is gespecialiseerd in verantwoorde en vakkundige afvoer van ICT apparatuur (ITAD) , veilige en professionele datavernietiging en recycling van EOL (End Of Life) ICT onderdelen en materialen. Wij bezitten de bedrijfsmiddelen, kennis en certificeringen om werkplekken en ICT apparatuur betrouwbaar en discreet af te voeren. Circulair en maatschappelijk verantwoord ondernemen is onze missie en onze activiteiten worden uitgevoerd conform de geldende wet- en regelgeving.  Van opkoop, transport, datavernietiging en recycling tot en met de update van uw CMDB en eindrapportage. Alles wordt voor u geregeld ! </Diensttext>
                        <Itemcontainer>
                        {Waaromitems.map(({ titel, text, foto, icoon }) => (
                            <Item id={"dienstitem" + icoon} onMouseEnter={(e) => this.Dothis(icoon, e)} onMouseLeave={(e) => this.Dothat(icoon, e)}>


                                <BorderDiv />
                                <IconTitle>
                                    <HoverBorder />
                                    {this.iconSwitch(icoon)}
                                    <h4>{titel}</h4>
                                </IconTitle>
                                <TextInnercontainer><p>{text}</p></TextInnercontainer>


                            </Item>

                        ))}
                    </Itemcontainer>
                    </Flexcontainer>

                    
                    <Fotocontainer >
                            <Styledlaptop />
                            <DienstimageContainer ref={this.fotoRef} >

                                            <DienstImages />
                            </ DienstimageContainer>
                            
                        </Fotocontainer>
                </Container>
            </>
        )
    }
}





const BorderDiv = styled.div`
    margin: 0 auto;
    height: 100%;
    position: absolute;
    border-bottom: 2px solid rgba(255,255,255,0.4);
    transform: translatey(-39px);
`
const TextInnercontainer = styled.div`
    overflow: hidden;
    font-size: 16px;
    margin-top: 20px;

    @media ${device.laptop}{
        height
    }

`

const IconTitle = styled.div`
    margin: 20px 0;
    width: 100%;
    display: inline;
    position: relative;
`

const HoverBorder = styled.div`
    height: 100%;
    position: absolute;
    padding-bottom: 17px;
    margin: 0 auto;
    width: 0%;
    border-bottom: 3px solid var(--green-secondary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    ${Item}:hover & {
                        width: 100%;
    }
`

const ButtonCont = styled.div`
`
const MarginButt = styled.div`
    margin: 0 auto;
    float: right;
`
const Button = styled.div`
    padding: 7px 0;
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    margin: 0 auto;
    :hover{

            background-color: var(--green-secondary);
            color: red;
            a{
                border: 2px solid rgba(0,0,0,0);
                color: var(--dark-background);

            }


            }
    a{
        list-style-type: none;
        text-decoration: none;
        font-weight: 900;
        z-index:110;
        padding: 5px;
        border: 2px solid white;
        box-sizing: content-box;
        transition: all 0.25s cubic-bezier(.44,0,.17,1);
       
    }

`
const Icoon = styled(FontAwesomeIcon)`
    font-size: 30px;
    display: inline-block;
    color: var(--green-secondary);
    padding-right: 20px;
`


export default Waarom