import React from "react"
import styled from "styled-components"
import Checkmark from "../icoonquerries/Checkmark"
import Hardeschijfimage from "../hardeschijfimage"
import Certificaatfoto from "./certificaatfoto"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import {device} from "../../queries"
import Componentheader from "./componentHeader"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Parallax, Navigation, Pagination} from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper.scss';
import {
    faCheck,
    faAt,
} from '@fortawesome/free-solid-svg-icons'



const Icoontje = styled(FontAwesomeIcon)`
    color: var(--green-secondary);
    font-size: 30px;
`
const Styledslide = styled.div`
    max-width: 1200px;
    height: 500px;
    margin: 0 auto;
    display: flex; 
    flex-direction: row;
    --swiper-navigation-color: var(--green-secondary);
    @media ${device.laptop}{
        flex-direction: column;
        height: 100%;
    }

`
const Left = styled.div`
    width: 49%;
    text-align: center;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    :before{
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    div{
        display: inline-block;
        vertical-align: middle;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
    }
    @media ${device.laptop}{
        width: 100%;
        display: none;
    }
    
`

const Secondframe = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 500px;
    max-width: 1200px;
    margin: 0 auto;


    div{
        width: 49%;
        height: 100%;
        box-sizing: border-box;
    }
    div:nth-child(2){
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 20;
        @media ${device.laptop}{
            justify-content: flex-start;
        }

    }
    @media ${device.laptop}{
        flex-direction: column;
        div{
            width: 100%;
            padding: 40px;
        }
        div:nth-child(1){
            display: none;
    
        }
        



    }
    }
`

const Absolution = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translate(30px 10px 10px);
    

    div{
        width: 1000px;
        height: 100%;
        transform: translateY(10px);
        img{
            
        }
    }

    @media ${device.laptop}{
        position: relative;
        div{
            width: 700px;

        }
        display: flex;
        flex-direction: column;
        justify-content: bottom;
        align-items: center;
        display: none;
    }
    @media ${device.mobileL}{
        transform: translateY(30px);
    }

`

const Header = styled.h2`
    text-align: center;
    font-size: 60px;
    span{
        color: var(--green-primary);
    }
`


const Slidercontent = styled.div`


    height: 500px;
        h4{
        color: white;
        margin-bottom: 10px;
    }
    li{
        margin: 10px;
        span{
            vertical-align: middle;
            margin-left: 10px;
        }
        color: rgba(255,255,255,1);
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: normal;
    }
    p{
        color: rgba(255,255,255,0.9);
        font-weight: 300;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: normal;
    }
    ul{
        
        margin: 30px 0;
        margin-bottom: 0%;
        padding-inline-start: 40px;
        
    }
    @media ${device.laptopL}{
        height: 100%;
        h4{text-shadow: 0px 0px 3px #000000ff;}
        p{text-shadow: 0px 0px 3px #000000ff;}
        li{text-shadow: 0px 0px 3px #000000ff;}
    }
    @media ${device.laptop}{
        height: 100%;

    }
`

const SlideReverse = styled.div`
    @media ${device.laptop}{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        width: 100%;
    }
`

const Right = styled.div`
    width: 90%;
    padding: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    @media ${device.laptop}{
        width: 100%;
    }

`

const Styledswiper = styled(Swiper)`
    background-color: var(--dark-background);
    ul{
        padding-inline-start: 0 !important;
    }

    @media ${device.tabletS}{
    }
    @media ${device.mobileL}{
    }
`


SwiperCore.use([Navigation, Pagination, Parallax ]);

const Gecertificeerd = () => {
    


    return (
           
        <>
            <Componentheader header="VEILIG & GECERTIFICEERD"></Componentheader>
            
            <Styledswiper
                tag="section"
                wrapperTag="ul"
                spaceBetween={0}
                speed={1000}
                slidesPerView={1}
                navigation
                Scrollspeed
                pagination={{ clickable: true }}
                

            >

                <SwiperSlide tag="li">
                <Slidercontent>
                    <Styledslide>

                        <Left data-swiper-parallax="25%">
                            <div>
                            <Hardeschijfimage/>
                            </div>
                        </Left>
                        <Right>
                            <h4 data-swiper-parallax="-25%" >Neem geen onnodig risico!</h4>
                            <p>U wilt absoluut voorkomen dat vertrouwelijke gegevens in verkeerde handen vallen.
                            <br />Daarom garanderen wij dat al uw gegevens 100% veilig en gecertificeerd worden vernietigd.</p>
                            <ul data-swiper-parallax="-25%">
                                <li><Icoontje icon={faCheck}></Icoontje><span>Softwarematige datavernietiging als partner van Blancco</span></li>
                                <li><Icoontje icon={faCheck}></Icoontje><span>Mechanische vernietiging volgens de richtlijnen van de Europese DIN66399 norm</span></li>
                                <li><Icoontje icon={faCheck}></Icoontje><span>Certificaat en eindrapportage incl serienummers als bewijslast voor de AVG</span></li>
                            </ul>
                        </Right>

                    </Styledslide>
                    </Slidercontent>
                </SwiperSlide>
         


                <SwiperSlide tag="li">
                    <SlideReverse>
                        <Absolution>
                            <div>
                                <Certificaatfoto/>
                            </div>
                        </Absolution>
                         <Slidercontent>

                             <Secondframe>
                                <div></div>
                                <div>
                                    <h4 data-swiper-parallax="-25%" >Een compliant partner!</h4>
                                    <p>Green Digital is in bezit van de wettelijke verplichte EU certificeringen.</p>
                                    <ul data-swiper-parallax="-25%">
                                        <li><Icoontje icon={faCheck}></Icoontje><span>Volledig gecertificeerd voor milieuvriendelijke afvoer (VIHB)</span></li>
                                        <li><Icoontje icon={faCheck}></Icoontje><span>Verwerking volgens wet- en regelgeving (WEEELABEX)</span></li>
                                        <li><Icoontje icon={faCheck}></Icoontje><span>Veilige datavernietiging (ISO27001)</span></li>
                                    </ul>
                                </div>
                            </Secondframe>
                        </Slidercontent>
                    </SlideReverse>
                </SwiperSlide>
            </Styledswiper>
        </>
    );
}



const Section = styled.div`
    width: 100%;
    background-color: rgba(57,64,66,1);
    background-repeat: repeat;
    background-size: auto;
    border-bottom: 3px solid var(--green-secondary);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
`


const Backgroundextra = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    background: linear-gradient(to bottom ,rgba(57,64,66,1), rgba(57,64,66,0.6));
`


  
  export default Gecertificeerd

  