import React from "react"
import { useStaticQuery, graphql, Link, withPrefix } from "gatsby"
import styled from "styled-components"
import styledimages from "./Dienstitems.json"
import Componentheader from "./componentHeader"
import { device } from "../../queries"
import Dienstitems from "./Dienstitems.json"
import { render } from "react-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import Img from "gatsby-image"
import Imagegarantie from "./imagegarantie"


const Colorspace = styled.div`
    
`

const Ghostgradient = styled.div`
    width: 100%;
    position: absolute;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.2) , rgba(5,60,0,1) );
    z-index: -1;
    display: none;
`

const Fonticon = styled(FontAwesomeIcon)`
    color: var(--dark-background);
    font-size: 20px;
    display: inline-block;
`


const Vinkje = () => {
    return (
        <svg version="1.1" id="Laag_1" x="20px" y="20px"
            viewBox="0 0 400 400" width="20px">

            <g id="XMLID_2_">
                <g id="XMLID_1_">
                    <path d="M366,123.7c-17.1,17.2-32,33.5-44,49.3v100.6c0,27.4-22.5,49.5-49.8,49.5H125.1
			C97.7,323,75,300.9,75,273.5V126.5C75,99.1,97.7,77,125.1,77h147.1c11.4,0,21.9,3.8,30.2,10.2c12.4-10.7,24.5-20,36-27.3
			c-17-17-40.4-27.9-66.3-27.9H125.1C73.4,32,31,74.8,31,126.5v147.1c0,51.7,42.4,94.5,94.1,94.5h147.1c51.7,0,93.8-42.8,93.8-94.5
			V126.5C366,125.5,366,124.6,366,123.7z"/>
                </g>
                <path d="M226.1,279H180c0,0-8.7-21.4-35.8-53.7c-27.6-32.9-63.1-45.4-63.3-45.6l26.2-42.9
		c1.3,0.7,37,4,65.6,41.8c19.2,25.4,24,39.5,31.8,52.7c0,0,101.9-148.5,183.8-170.1c0,0,7,4.6,7,4.6
		C298.4,149.8,265.9,212,226.1,279z"/>
            </g>
        </svg>


    )
}



const Container = styled.div`
    width: 100%;
    padding: 40px 0;
    
    box-shadow: inset 0 0 10px rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.07);
    z-index: -20;
    
    display: flex;
    align-items: center;
    
    @media ${device.laptop}{
        padding: 0;
    }
    


    
`

const Innercontainer = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
    flex-wrap: wrap;



`
const Dienstimage = styled(Img)`
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin: -50px auto 0 auto;
    border: 10px solid #fafcff;
`
const Item = styled.div`
    width: 32%;
    padding: 0 0 15px 0;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    @media ${device.laptop}{
        width: 100%;
        margin-bottom: 40px;
    }
`

const Itemcontainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    
    h4{
        font-size: 18px;
        padding: 15px 10px;
        padding-top: 20px 0;
        font-size: 20px;
        font-weight: 900;
        text-align: left;
        border-bottom: 2px solid rgba(0,0,0,0.1);
        @media ${device.laptop}{
            border-bottom: none;
        }
    }
    p{
        padding: 10px;
        text-align: left;
        min-height: 90px;
    }
`

const Leesmeer = styled.div`
    width: 105px;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all 0.2s cubic-bezier(.44,0,.17,1);
    float: right;
    


    a{
        padding: 3px 5px;
        display: inline-block;
        z-index: 1;
        color: var(--dark-text);
        transition: all 0.2s cubic-bezier(.44,0,.17,1);
        position: relative;
        font-weight: 900;
        font-size: 18px;
        text-align: center;
        border-right: 2px solid var(--green-primary);
        border-left: 2px solid var(--green-primary);
        border-radius: 10px;
        
    }

    :hover{
        a{
        background-color: rgb(233 255 232);
        padding: 3px 2px;
        }
            
    }

`
const Textvak = styled.div`


`

const Maintext = styled.div`
    width: 1200px;
    margin: 0 auto;
    background-color: white;
    box-sizing: border-box;
    margin-bottom: 100px;
    
    h2{
        display: block;
        color: var(--green-primary);
        padding: 20px 0;
        font-size: 40px;
        text-align: center;


    }
    p{
        
        padding-top: 10px;
        letter-spacing: normal;
        display: block;
        width: 1000px;
        letter-spacing: 1px;
        margin: 0 auto;

        

    }
`

const Dienstheader = styled.div`
    background-color: var(--green-primary);
    padding: 20px;  
    h2{
        color: white;
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        font-size: 40px;
    }  
`

const StyledImg = styled(Img)`
    height: 200px;
`

const Fotos = () => {
    const data = useStaticQuery(graphql`{
        allFile(filter: {relativeDirectory: {eq: "diensten"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 700) {
                            ...GatsbyImageSharpFluid
                            originalName
                        }
                    }
                }
            }
        }
    }`)

    return (Dienstitems.map(  item => (
        <Item>
            <Itemcontainer>
                <StyledImg fluid={data.allFile.edges[item.icoon].node.childImageSharp.fluid} />
                <Textvak>

                    <h4>{item.titel}</h4>
                    <p>{item.text}</p>
                    <Leesmeer><Link to={item.link}>Lees meer</Link></Leesmeer>
                </Textvak>
            </Itemcontainer>
        </Item>

    )))
}


class Diensten extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <Colorspace>
                    <Componentheader header="DIENSTEN"></Componentheader>
                    <Container>


                        <Innercontainer>
                            <Fotos></Fotos>
                        </Innercontainer>

                    </Container>
                </Colorspace>
            </>
        )

    }
}


export default Diensten;