import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const ImageWrapper = styled.div`
width: inherit;
overflow: hidden;
height: inherit;
width: 100%;
position: relative;
`


const Dienstfoto = styled.div`
opacity: 0;
position: absolute;
height: 100%;
width: 100%;
transition: opacity 1s cubic-bezier(.44,0,.17,1);
height: inherit;
overflow: hidden;

`

const DienstImages = () => {
    const data = useStaticQuery(graphql`
{
allFile(filter: {relativeDirectory: {eq: "waaromfotos"}}) {
    edges {
        node {
            childImageSharp {
                fluid(maxWidth: 700) {
                    ...GatsbyImageSharpFluid
                    originalName
                }
            }
        }
    }
}
}`)

    return (data.allFile.edges.map(({ node }) => (
        <Dienstfoto>
            <ImageWrapper>
                <Img ref={node.originalName} fluid={node.childImageSharp.fluid} />
            </ImageWrapper>
        </Dienstfoto>
    )))

}

export default DienstImages;