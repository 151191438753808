import React from "react"
import Img from "gatsby-image"
import { StaticQuery, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Componentheader from "./componentHeader"
import Greenachtergrond from "./../Greenachtergrond"
import { device } from "../../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRecycle, faHdd, faDesktop, faSeedling } from "@fortawesome/free-solid-svg-icons"
import Statitems from "./Statitems.json"
import BackgroundImage from "gatsby-background-image"
import ComponentHeader from "./componentHeader"







const Innercontainer = styled.div`
    margin:  0 auto;
    width: 100%;
    max-width: 1500px;
    display: flex;
    justify-content: space-between;
    z-index: 200 !important;
    height: 100%;
    align-items: center; 
    flex-wrap: wrap;
    
`

const Item = styled.div`
    width: 25%;
    @media ${device.tablet}{
      width: 50%;
      padding-bottom: 20px;
      &:nth-child(3), &:nth-child(4){
        border-top: 2px solid rgba(0,0,0,0.1);
        padding-top: 20px;
        }
    }

    @media ${device.mobileM}{
      width: 100%;
      border-top: 2px solid rgba(0,0,0,0.1);
      padding: 20px 0;
    }
`

const Iteminner = styled.div`
  display: flex;
  flex-direction: column;
`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 60px;
    display: inline-block;
    color: #ffa300;
    margin: 0 auto;
    padding: 20px 0;
    

`

const Titel = styled.h4`
font-weight: 500;
color: var(--dark-text);
text-align: center;
font-weight: 600;
font-size: 24px;
`

const Nummer = styled.p`
    font-size: 24px;
    color: var(--dark-text);
    font-weight: 900;
    text-align: center;
`

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query{
        desktop:file(relativePath: {eq:"subtle4.png"}){
          childImageSharp{
            fluid(quality:100, maxWidth:1920){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage tag="section" className={className} fluid={imageData}>
          {children}
        </BackgroundImage>
      )
    }}
  />
)


const Backgroundthing = styled.div`
    width: 100%;
    position: relative;
    padding: 20px 0;
  `


class Stats extends React.Component {
  constructor(props) {
    super(props);
  }

  iconSwitch(icoon) {
    switch (parseInt(icoon)) {
      case 0:
        return (<Icoon icon={faDesktop} />);

      case 1:
        return (<Icoon icon={faHdd} />);
      case 2:
        return (<Icoon icon={faRecycle} />);
      case 3:
        return (<Icoon icon={faSeedling} />);

    }
  }

  render() {
    return (
        <>
          <ComponentHeader header="Statistieken"></ComponentHeader>
          <Backgroundthing>
          
            <Innercontainer>
              {Statitems.map(x => (
                <Item>
                  <Iteminner>
                    <Titel> {x.titel} </Titel>
                    {this.iconSwitch(x.icoon)}
                    <Nummer> {x.aantal} </Nummer>
                  </Iteminner>
              
                </Item>
              ))}
            </Innercontainer>
          </Backgroundthing>
        </>
    )
  }
}


export default Stats