import React from "react"
import styled from "styled-components"

const Buttino = styled.div`
    margin-top: 20px;
    display: inline-block;
    a{
        color: var(--green-primary);
        font-size: 24px;
        font-weight: 500;
    }

    
`
const Arrowline = styled.div`
    background-color: white;
    height: 3px;
    width: 0%;
    background-color: white;
    opacity: 0;
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    border-radius: 2px;
    margin-top: 2px;
    z-index: 100;
    
    ${Buttino}:hover & {
        width: 70%;
        opacity: 1;
        margin: 0 auto;
        
    }
    div{
        float: right;
        margin-right: 6px;
        div{
            position: absolute;
        }
        div:nth-child(1){
            width: 10px;
            height: 3px;
            background-color: white;
            transform: rotate(45deg);
            margin-top: -3px;
            border-radius: 2px;
        }
        div:nth-child(2){
            width: 10px;
            height: 3px;
            background-color: white;
            transform: rotate(315deg);
            margin-top: 3px;
            border-radius: 2px;
        }
    }
`


const Arrow = ({text, link,className}) => {
    return(
        <Buttino className={className}>
            <a href={link}>{text}</a>
            <Arrowline>
                <div>
                    <div></div>
                    <div></div>
                </div>
            </Arrowline>
        </Buttino>
    )
}

export default Arrow;