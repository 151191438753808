import React from "react"
import styled from "styled-components"
import { device } from "../../queries"


const Comphead = styled.div`
    text-align: center;
    margin: 0 auto;
`


const Header = styled.h2`
    font-weight: 900;
    font-size: 56px;

    margin: 90px auto 40px auto;
    text-align: center;
    color: var(--dark-text);  
    @media ${device.tablet}{
        font-size: 40px;
        margin: 40px auto 20px auto;
    }
`



export default function Componentheader({ header, subheader }) {
    return (
        <Comphead>
            <Header>{header}</Header>
        </Comphead>
    );
}