
import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql} from "gatsby"
import styled from "styled-components"
import {device} from "../../queries"



const Certbar = styled.div`
    width: 100%;
    background-color: var(--dark-background);
    padding:  20px 0 40px 0;


`

const CertbarInner = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`

const Certimage = styled(Img)`
    width: 150px;
    height: 75px;
    overflow: visible;
    img{
        overflow: visible !important;
        object-fit: contain !important;
    }
    picture{
        overflow: visible !important;
        img{
            object-fit: contain !important;
            overflow: visible !important;
        }
    }
    }



`

const Certificaten = () =>{
    const data =  useStaticQuery(graphql`
        {
        allFile(filter: {relativeDirectory: {eq: "certificaten"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxHeight: 150) {
                            ...GatsbyImageSharpFluid
                            aspectRatio
                            originalName
                            
                            
                        }
                    }
                }
            }
        }
        }`
    )
    console.log(data);
    return(
        <Certbar>
            <CertbarInner>
                {data.allFile.edges.map(({node}) => (<Certimage fluid={node.childImageSharp.fluid}></Certimage>))}
            </CertbarInner>
        </Certbar>
    )
}




export default Certificaten