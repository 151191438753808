import React from "react"
import Img from "gatsby-image"
import { StaticQuery, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Dienstitems from "./Dienstitems.json"
import Componentheader from "./componentHeader"
import { device } from "../../queries"
import BackgroundImage from "gatsby-background-image"
import { render } from "react-dom"
import Arrowbutton from "./arrowbutton.js"


const BackgroundSection = ({ className, logo, subheader }) => (
    <StaticQuery
        query={graphql`
      query{
        desktop:file(relativePath: {eq:"voorkant_site5.jpg"}){
          childImageSharp{
            fluid(quality:90, maxWidth:1920){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
        render={data => {
            const imageData = data.desktop.childImageSharp.fluid
            return (
                <Img tag="section" className={className} fluid={imageData}>
                </Img>

            )
        }}
    />
)


const Mapping = () => {
    return (

        <path d="M640,0H110.7C94.5,46.2,85.6,95.9,85.6,147.6c0,94.9,29.8,182.9,80.5,255.1c101.1-64.2,168.3-177.2,168.3-305.8h-27.6
	l55.2-56.4l55.2,56.4h-30.8c0,145.1-74.7,272.8-187.8,346.7c11.5,12.8,23.6,25,36.5,36.4h405V0z"/>
    )

}



const Container = styled.div`
    width: 100%;
    display: flex;
    height: 550px;
    @media ${device.tablet}{
        height: auto;

    }
`

const Ict = styled.h1`
    color: var(--dark-text);
            margin-bottom: 20px;
            font-size: 90px;
            font-weight: 900;    
            font-family: Lato;  
            color: var(--dark-text);  
            display: inline-block;
            line-height:1,2;

`


const Onsvak = styled.h1`
    color: var(--dark-text);
            margin-bottom: 20px;
            margin-top: -30px;
            font-size: 50px;
            font-weight: 900;    
            font-family: Lato;  
            color: var(--dark-text);  
            display: inline-block;
            line-height:1,2;

`


const Styledarrow = styled.a`
        margin-top: 20px;
        font-size: 28px;
        font-weight: 700;
        background-color: var(--green-primary);
        color: white;
        padding: 10px;
        border-radius: 7px;
        display: inline-block;
        

`



const Headcontainer = styled.div`
    color: var(--dark-text);
    margin-bottom: 40px;

    @media ${device.tablet}{
        margin-bottom: 0;
    }
    h1{
        font-weight: 900;
        font-size: 35px;
    }
`
const InnerContainer = styled.div`

        max-width: 600px;
        float: right;
        @media ${device.laptopL}{
            transform: translateX(100px);
        }
        @media ${device.tablet}{
            transform: none;
            float: none;
            margin: 0 auto;
            height: auto;
            max-width: 100%;
            h1{
                font-size: 28px;
                padding: 0 10px;
                text-align: center;

            }
        }


        }
        p{

            font-size: 18px;
            letter-spacing: 1px;
            font-weight: 400;
            line-height: 1.7;
            @media ${device.tablet}{
                padding: 20px 20px 40px 20px;
                
            }
            @media ${device.mobileM}{
                font0size: 16px;
                line-height: 1.5;
        }
        }
`

const Paddingcontainer = styled.div`
    position: relative;
    @media ${device.tablet}{
    }
`


const Left = styled.div`
        width: 50%;
        height:100%;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        @media ${device.tablet}{
            width: 100%;
        }
        

`

const Right = styled.div`
        width: 50%;
        float: right;
        @media ${device.tablet}{
            display: none;
        }
`
const Imagefile = styled(BackgroundSection)`
        width: 100%;
        height: 100%;
        clip-path: url(#clippable);
        float: right;
        img{
            object-position: top center !important;
        }


`

const BannerImage = styled(BackgroundSection)`
        display: none;
        width: 100%;
        height: 200px;
        img{
            object-position: top center !important;
        }
        @media ${device.tablet}{
            display: inline-block;

        }
`



const Vector = styled.svg`
        width: 0px;
        height: 0px;

`

const StyledClipPath = styled.clipPath`
        transform: scale(1.6,1.6);
        @media ${device.laptopL}{
            transform: scale(1.3,1.3);
        }

`

const Maincontent = () => {
    return (
        <>
            <Container>
                
                <Left>

                    <InnerContainer>
                        
                        <Paddingcontainer>
                            <BannerImage/>

                            <Headcontainer>
                            <h1>Veilig en verantwoord uw gebruikte ICT apparatuur verkopen?</h1>
                            </Headcontainer>
                        </Paddingcontainer>
                        <p>  Green Digital ontzorgt organisaties door het veilig en vakkundig afvoeren van de oude werkplek infrastructuur. Gebruikte ICT apparatuur kopen wij in voor de maximale restwaarde. Datadragers worden gecertificeerd vernietigd volgens Europese richtlijnen. Milieuverantwoorde recycling van materialen voeren wij uit conform de wettelijke milieu- en compliance-eisen.
                        </p> 

                     
                    </InnerContainer>
                </Left>

                <Right>

                    <Imagefile />
                    <Vector>
                        <defs>
                            <StyledClipPath id="clippable">
                            

                            <path id="XMLID_2_" d="M1000,0H179.6C170.8,187.1,105.4,359.4,0,500l1001,3L1000,0z"/>
                            </StyledClipPath>
                        </defs>
                    </Vector>
                </Right>
            </Container >
            <div></div>
        </>
    );
}

export default Maincontent;